import * as React from 'react'

import * as Icon from '@/components/core/internicons'
import { c } from '@/utils/etc'

import type { SharedFieldInputProps } from '../field-input/field-input'

export interface CheckboxInputProps extends SharedFieldInputProps {
  name: string
  checked?: boolean | undefined
  label?: string
  error?: boolean
  disabled?: boolean
  indeterminate?: boolean
  bubble?: boolean
  renderLabel?: () => React.ReactNode
  onChange?: (v: any) => void
  onBlur?: () => void
  onFocus?: () => void
  onClick?: (v: any) => void
  useSelectAll?: boolean
  selectAll?: boolean
}

const CheckboxInput: React.FCC<CheckboxInputProps> = ({
  // name,
  label,
  error: _error,
  disabled,
  passthrough,
  className,
  containerClassName,
  inputWrapperClassName,
  labelClassName,
  indeterminate,
  bubble,
  renderLabel,
  onClick,
  selectAll,
  useSelectAll,
  ...props
}) => {
  const [hover, setHover] = React.useState(false)

  if (useSelectAll === true) {
    props.checked = selectAll
  }

  return (
    <button
      type="button"
      onKeyDown={(evt) => {
        if (evt.code === 'Space' || evt.code === 'Enter') {
          props.onChange?.(!props.checked)
          onClick?.(evt)
        }
      }}
      onClick={onClick}
      className={c(containerClassName)}
    >
      <label
        className={c(
          !disabled && 'cursor-pointer',
          'flex select-none items-start space-x-3',
          inputWrapperClassName
        )}
      >
        <span className="relative flex items-center justify-center mt-[2.5px]">
          <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            className={c(
              'h-[18px] w-[18px] rounded-[4px] border',
              (props.checked || indeterminate) &&
                'border-0 bg-primary-300 hover:bg-primary-400',
              !props.checked &&
                'border-light-gray-400 bg-white hover:border-light-gray-500'
            )}
          >
            {props.checked && !indeterminate && (
              <Icon.Check className="text-white" />
            )}
            {indeterminate && (
              <Icon.Minus className="text-white rounded-[4px]  border-0 bg-primary-300 hover:bg-primary-400" />
            )}
          </div>

          {bubble && (
            <span
              className={c(
                'absolute transform-gpu rounded-full bg-light-gray-200 p-4 transition-transform',
                hover ? 'scale-100' : 'scale-0'
              )}
            />
          )}
        </span>
        <input
          hidden
          className={c(className)}
          type="checkbox"
          disabled={disabled}
          {...props}
          {...passthrough}
          style={{ border: '2px solid red !important' }}
        />
        {label && (
          <p
            className={c(
              'text-body-md text-left font-medium text-light-gray-500',
              labelClassName
            )}
          >
            {label}
          </p>
        )}
        {renderLabel && renderLabel()}
      </label>
    </button>
  )
}

export default CheckboxInput
